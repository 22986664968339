<template>
  <div class="wrapper">
    <header class="home-page header" :class="{'open-menu': openedSidebar}">
      <div class="content content_in">
        <div class="header__menu" @click="openedSidebar = !openedSidebar">
          <div class="icon-menu">
            <div class="sw-topper"></div>
            <div class="sw-bottom"></div>
            <div class="sw-footer"></div>
          </div>
        </div>
        <a href="/" class="header__logo">
          <img :src="require('@/assets/images/entry/logo-white.svg')" alt="" />
        </a>
        <div class="header__manager">
          <div class="manager">
            <div class="manager__item">
              <a href="#" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__item">
              <a href="#" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="#" target="_blank" class="manager__link"
                >@publisher_push_house</a
              >
            </div>
          </div>
        </div>
        <nav class="header__entry entry-menu">
          <ul>
            <li><a href="#" class="btn _entry">{{$t('landing.login')}}</a></li>
            <li><a href="#" class="btn _registration">{{$t('landing.register')}}</a></li>
          </ul>
        </nav>
        <div class="header__lang language">
          <select-language simple />
        </div>
      
        <a href="#" class="header__login">
          <svg
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.25 20.2552H21.75C25.7541 20.2552 29 23.494 29 27.4892C29 28.2882 28.3508 28.936 27.55 28.936C26.8064 28.936 26.1935 28.3775 26.1098 27.6579L26.0926 27.2342C25.9652 25.0401 24.2045 23.2833 22.0056 23.1562L21.75 23.1488H7.25C4.84756 23.1488 2.9 25.0921 2.9 27.4892C2.9 28.2882 2.25081 28.936 1.45 28.936C0.649187 28.936 0 28.2882 0 27.4892C0 23.5991 3.07734 20.4262 6.93551 20.2619L7.25 20.2552H21.75H7.25ZM14.5 0C19.3049 0 23.2 3.88653 23.2 8.68081C23.2 13.4751 19.3049 17.3616 14.5 17.3616C9.69512 17.3616 5.8 13.4751 5.8 8.68081C5.8 3.88653 9.69512 0 14.5 0ZM14.5 2.89359C11.2967 2.89359 8.69995 5.48461 8.69995 8.6808C8.69995 11.877 11.2967 14.468 14.5 14.468C17.7032 14.468 20.3 11.877 20.3 8.6808C20.3 5.48461 17.7032 2.89359 14.5 2.89359Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </header>
    <section class="offer" @click="selectingCountry = false">
      <div class="content">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="offer__text">
              <div class="offer__slogan"> {{ $t("landing.help_making_cash") }}</div>
              <h1 v-html="$t('landing.header')"></h1>
              <p>
                {{$t('landing.platform')}}
              </p>
            </div>
            <div class="offer-direction">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="offer-direction__item _webmaster">
                    <h2>{{$t('landing.webmaster')}}</h2>
                    <div class="offer-direction__text">
                      {{$t('landing.create_platform')}} <br />
                      {{$t('landing.set_code_upload_files')}} <br />
                     {{$t('landing.get_80_percent')}}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="offer-direction__item _arbitrator">
                    <h2>{{$t('landing.arbitrage')}}</h2>
                    <div class="offer-direction__text">
                      {{$t('landing.create_platform')}} <br />
                      {{$t('landing.select_lending')}} <br />
                     {{$t('landing.launch_traffic')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="c-calculate">
              <div class="calculate">
                <div class="calculate__content">
                  <div class="calculate__title">{{$t('landing.calculate_income')}}</div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-item pb-1">
                        <div class="form-item__field">
                          <label class="radio-text-icon d-block">
                            <input type="radio" name="device" checked />
                            <span class="radio-text-icon__visible"
                              ><span class="icon-mobile2"></span> Mobile</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-item pb-1">
                        <div class="form-item__field">
                          <label class="radio-text-icon d-block">
                            <input type="radio" name="device" />
                            <span class="radio-text-icon__visible"
                              ><span class="icon-desktop2"></span> Desktop</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-item">
                        <div class="form-item__field">
                          <div
                            class="dropdown bootstrap-select w100 _select-country dropup"
                            :class="{'show': selectingCountry}"
                          >
                            <select
                              class="selectpicker w100 _select-country"
                            >
                              <option class="bs-title-option" value=""></option>
                              <option value="2">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. At, repellendus.
                              </option>
                              <option value="2">Азербайджан</option>
                              <option value="2">Аландские острова</option>
                              <option value="2">Албания</option>
                          </select><button
                              type="button"
                              @click.stop="selectingCountry = true"
                              class="btn dropdown-toggle btn-light bs-placeholder"
                              data-toggle="dropdown"
                            >
                              <div class="filter-option">
                                <div class="filter-option-inner">
                                  <div class="filter-option-inner-inner">
                                   {{ selectedCountry === '' ? $t('landing.choose_country'): selectedCountry}}
                                  </div>
                                </div>
                              </div>
                            </button>
                            <div
                              class="dropdown-menu"
                              :class="{'show': selectingCountry}"
                              
                            >
                              <div class="bs-searchbox">
                                <input
                                  @click.stop="selectingCountry = true"
                                  type="search"
                                  class="form-control"
                                  autocomplete="off"
                                  role="combobox"
                                  aria-label="Search"
                                  aria-controls="bs-select-2"
                                  aria-autocomplete="list"
                                />
                              </div>
                              <div
                                class="inner show"
                                role="listbox"
                                id="bs-select-2"
                                tabindex="-1"
                                style="max-height: 215px; overflow-y: auto"
                              >
                                <ul
                                  class="dropdown-menu inner show"
                                  role="presentation"
                                  style="margin-top: 0px; margin-bottom: 0px"
                                >
                                  <li v-for="country, index in countries" :key="index">
                                    <a
                                      @click="selectedCountry = country.country_name_en;selectingCountry = false"
                                      role="option"
                                      class="dropdown-item"
                                      ><span class="text"
                                        >{{country.country_name_en}}</span
                                      ></a
                                    >
                                  </li>
                              
                                  
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-item">
                        <div class="form-item__label text-center">
                         {{$t('landing.traffic_amount')}}
                        </div>
                        <div class="form-item__field">
                          <div class="form-range-traffic">
                            <span style="position: absolute">
                              <span id="trafficValue" class="p-0"></span>
                            </span>
                            <input
                              type="range"
                              min="1000"
                              step="1000"
                              max="1000000"
                              value="134567"
                              id="trafficRange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="calculate__label text-center">
                     {{$t('landing.average_income')}}
                  </div>
                  <div class="calculate__total text-center">≈ 7 456 USD</div>
                  <div class="calculate__submit text-center">
                    <button class="btn _bg-green2" @click="calcIncome">{{$t('landing.start')}}</button>
                  </div>
                </div>
              </div>
              <div class="calculate-caption icon-i">
               {{$t('landing.calc_info')}}
              </div>
            </div>
          </div>
        </div>
        <div class="mouse-animation"></div>
      </div>
    </section>
    <section class="benefits">
      <div class="content">
        <h2 class="title">{{$t('landing.why_we')}}</h2>
        <div class="row _offset0">
          <div
            class="col-6 col-lg-3 d-flex"
            v-for="(benefit, index) in benefits"
            :key="index"
          >
            <div class="benefit">
              <div class="benefit__icon">
                <img :src="benefit.image" alt="" />
              </div>
              <div class="benefit__value">{{ benefit.value }}</div>
              <div class="benefit__desc">{{ formatLabel(benefit.info) }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="formats">
      <div class="content">
        <h2 class="title">{{$t('landing.ad_formats_title')}}</h2>
        <ul class="formats__tubs">
          <li>
            <a
              @click.prevent="currentFormat = 0"
              href="#"
              :class="{ active: currentFormat === 0 }"
            >
              <span class="icon-format-1"></span>
              Push Notifications
            </a>
          </li>
          <li>
            <a
              @click.prevent="currentFormat = 1"
              href="#"
              :class="{ active: currentFormat === 1 }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 22 23"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.62919 0.235352C5.78766 0.235352 4.34075 1.702 4.34075 3.51196V3.69743C3.02537 4.14728 2.1046 5.36006 2.1046 6.78725V6.97272C0.920764 7.42258 0 8.63667 0 10.0625V18.7966C0 20.6053 1.44692 22.0719 3.28844 22.0719H18.5468C20.3884 22.0719 21.8353 20.6053 21.8353 18.7966V10.0625C21.8353 8.63667 20.9145 7.42258 19.5991 6.97272V6.78725C19.5991 5.36006 18.6784 4.14728 17.4945 3.69743V3.51196C17.4945 1.702 15.9161 0.235352 14.2061 0.235352H7.62919ZM16.3105 5.69582V5.69582H15.2582H6.44514H5.39284V5.69582C4.86669 5.69582 4.34054 6.18514 4.34054 6.78759H17.4943C17.4943 6.18514 16.9682 5.69582 16.3105 5.69582ZM6.44642 3.51219H15.2595C15.2595 2.90844 14.7333 2.42043 14.2072 2.42043H7.63026C6.97257 2.42043 6.44642 2.90844 6.44642 3.51219ZM18.5465 8.97104V8.97104C19.0726 8.97104 19.5988 9.46036 19.5988 10.0628V18.7969C19.5988 19.3994 19.0726 19.8887 18.5465 19.8887H3.2881C2.63041 19.8887 2.10426 19.3994 2.10426 18.7969V10.0628C2.10426 9.46036 2.63041 8.97104 3.2881 8.97104V8.97104H4.3404H17.4942H18.5465Z"
                  fill="currentColor"
                />
              </svg>
              In-Page
            </a>
          </li>
          <li>
            <a
              @click.prevent="currentFormat = 2"
              href="#"
              :class="{ active: currentFormat === 2 }"
            >
              <span class="icon-format-3"></span>
              On-Click
            </a>
          </li>
          <li>
            <a
              @click.prevent="currentFormat = 3"
              href="#"
              :class="{ active: currentFormat === 3 }"
            >
              <span class="icon-format-4"></span>
              Native
            </a>
          </li>
        </ul>
        <div class="tubs-content">
          <div
            class="tubs-content__item"
            :class="{ active: currentFormat === format.id }"
            v-for="(format, index) in formats"
            :key="index"
          >
            <div class="formats__item">
              <div class="row flex-row-reverse">
                <div class="col-12 col-lg-8">
                  <div class="formats-preview">
                    <div class="formats-preview__body">
                      <img
                        :src="format.image"
                        alt=""
                        class="formats-preview-img"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 align-self-center">
                  <div class="formats__text">
                    <h3>{{ format.name }}</h3>
                    <p>{{ formatLabel(format.info) }}</p>
                    <ul
                      v-for="option in formats[index].listOptions"
                      :key="option"
                    >
                      <li>{{ formatLabel(option) }}</li>
                    </ul>
                  </div>
                  <div class="formats__btn">
                    <button class="btn _big">{{$t('landing.try')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="price">
      <div class="content">
        <div class="row align-items-center flex-row-reverse">
          <div class="col-12 col-lg-5 d-flex">
            <div class="price__text">
              <div class="price__before-title">Максимальный доход</div>
              <h2 class="title text-md-left">Web Push</h2>
              <ul class="price__list">
                <li>
                  <p><strong>Revshare</strong></p>
                  <p>
                    Выплаты по RevShare приносят прибыль в течение всей жизни
                    push-подписки.
                  </p>
                </li>
                <li>
                  <p><strong>CPL</strong></p>
                  <p>
                    Оплата производится за подписку каждого уникального
                    пользователя на push-рассылку
                  </p>
                </li>
              </ul>
              <div class="price__btn _pc">
                <button class="btn _big">Присоединиться</button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="price__slider">
              <div class="price__slider-item">
                <div class="c-table">
                  <div class="inner">
                    <table class="table _no-wrap" role="grid">
                      <thead>
                        <tr>
                          <th><strong>Страна</strong></th>
                          <th><strong>Revshare</strong></th>
                          <th><strong>CPL</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <div class="table-platform">
                              <span class="icon-android"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-win"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-apple"></span>
                            </div>
                          </td>
                          <td>
                            <div class="table-platform">
                              <span class="icon-android"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-win"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-apple"></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-1.png" alt="" />
                              Соедененные Шаты
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.04 <span class="table-sep">|</span> 0.04
                            <span class="table-sep">|</span> 0.04
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-1.png" alt="" />
                              Соедененные Шаты
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.04 <span class="table-sep">|</span> 0.04
                            <span class="table-sep">|</span> 0.04
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-1.png" alt="" />
                              Соедененные Шаты
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-1.png" alt="" />
                              Соедененные Шаты
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.0045 <span class="table-sep">|</span> 0.0045
                            <span class="table-sep">|</span> 0.0045
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="country">
                              <img src="images/home/flag-9.png" alt="" />
                              Украина
                            </div>
                          </td>
                          <td>
                            $ 45 <span class="table-sep">|</span> 45
                            <span class="table-sep">|</span> 45
                          </td>
                          <td>
                            $ 0.04 <span class="table-sep">|</span> 0.04
                            <span class="table-sep">|</span> 0.04
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row align-items-center flex-row-reverse justify-content-between pt-1"
            >
              <div class="col-12 col-md-4">
                <div class="slider-controls justify-content-md-end">
                  <button class="btn" disabled>
                    <svg
                      width="6"
                      height="8"
                      viewBox="0 0 6 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.587736 4.59696C0.192522 4.31807 0.192523 3.73196 0.587737 3.45308L4.84072 0.45196C5.30442 0.12475 5.94431 0.456377 5.94431 1.0239L5.94431 7.02613C5.94431 7.59366 5.30442 7.92528 4.84072 7.59807L0.587736 4.59696Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                  <div class="slider-controls__pagination">
                    <span>1</span>/8
                  </div>
                  <button class="btn">
                    <svg
                      width="6"
                      height="8"
                      viewBox="0 0 6 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.41226 4.59696C5.80748 4.31807 5.80748 3.73196 5.41226 3.45308L1.15928 0.45196C0.695583 0.12475 0.0556942 0.456377 0.0556942 1.0239L0.0556945 7.02613C0.0556945 7.59366 0.695585 7.92528 1.15928 7.59807L5.41226 4.59696Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-7 col-md-8">
                <div class="price__caption">
                  * Цены указаны за 1000 подписок. Полный список цен по всем ГЕО
                  вы найдете у себя в кабинете
                </div>
              </div>
            </div>
            <div class="price__btn _mobile">
              <button class="btn _big">Присоединиться</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="start">
      <div class="content">
        <div class="start__content">
          <div class="row flex-row-reverse">
            <div class="col-12 col-lg-6">
              <div class="start__desc">
                <h2>Не знаете с чего начать?</h2>
                <p>
                  Смотрите наш бесплатный гайд и зарабатывайте на пуш-подписках,
                  словно вы делали это всю жизнь!
                </p>
                <a href="#" class="btn _bg-green _big">Смотреть Гайд</a>
              </div>
            </div>
            <div class="col-12 col-lg-6 align-self-end">
              <div class="start__thumb">
                <img src="../assets/images/home/start.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="payments">
      <div class="content">
        <div class="banners">
          <div
            class="banners-slider"
            v-for="(banner, index) in banners"
            :key="index"
          >
            <div class="banners-slider__item" v-show="currentBanner === index">
              <a href="#" target="_blank" class="banner-item">
                <picture>
                  <source :srcset="banner.mob" media="(max-width: 767px)" />
                  <img :src="banner.image" alt="" />
                </picture>
              </a>
            </div>
          </div>
          <ul class="slider-pagination">
            <li
              v-for="(banner, index) in banners"
              :key="index"
              :class="{ 'slider-pagination__active': currentBanner === index }"
              @click="currentBanner = index"
            ></li>
          </ul>
        </div>
        <div class="payments__list">
          <div class="row _offset14">
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/webmoney.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img
                  src="../assets/images/home/payment/capitalist.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/cards.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/tether.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/qiwi.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/youmoney.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="footer__top">
        <div class="content">
          <div class="row justify-content-between">
            <div class="col-12 col-lg-2 col-md-3">
              <div class="footer__logo">
                <img
                  src="../assets/images/entry/logo-white.svg"
                  alt=""
                  width="155"
                />
                <span
                  >By
                  <a href="https://push.house/" target="_blank"
                    >Push.House</a
                  ></span
                >
              </div>
            </div>
            <div class="col-12 col-lg-10 col-md-9 pl-lg-5">
              <div class="footer__contacts">
                <ul class="contacts-list">
                  <li><a href="#" class="icon-telegram2">Leo31PH</a></li>
                  <li><a href="#" class="icon-telegram2">Robert_ph</a></li>
                  <li><a href="#" class="icon-skype">Pushhouse</a></li>
                  <li>
                    <a href="#" class="icon-email2"
                      >push.house.partner@gmail.com</a
                    >
                  </li>
                </ul>
              </div>
              <div class="footer__desc">
                Мы создали отличные условия для эффективной работы наших
                партеров и зароботка для вебмастеров и арбитражников. <br />
                Инструменты нашего личного кабинета позволяют самостоятельно
                отслеживать ваши конверсии, доход. <br />
                Мы постоянно работаем над улучшением нашей системы для вашего
                комфорта и стабильного дохода.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="content">
          <div
            class="row align-items-center justify-content-between flex-row-reverse"
          >
            <div class="col-12 col-md-auto">
              <ul class="footer__menu">
                <li><a href="#" target="_blank">Условия использования</a></li>
                <li>
                  <a href="#" target="_blank">Политика конфиденциальности</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-auto">
              <div class="footer__copyright">© Partners.House, 2019 - 2023</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <aside class="sidebar" :class="{'active':openedSidebar}">
      <div class="sidebar__content mt-2">
        <select-language leftMenu />
        <div class="sidebar__info">
          <div class="sidebar__lang language">
            <select
              class="selectpicker w100"
              data-live-search="false"
              data-actions-box="false"
              title="Не выбрано"
            >
              <option
                value="ru"
                data-content="<span class='lang-item'><img src='images/ru.svg' alt=''> RU</span>"
              ></option>
              <option
                value="ru"
                data-content="<span class='lang-item'><img src='images/ua.svg' alt=''> UA</span>"
                selected
              ></option>
            </select>
          </div>
        </div>
        <nav class="sidebar__entry entry-menu">
          <ul>
            <li><a href="#" class="btn _entry">Войти</a></li>
            <li><a href="#" class="btn _registration">Регистрация</a></li>
          </ul>
           
        </nav>
        <div class="sidebar__manager">
          <div class="manager">
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">Менеджер</div>
              <a href="#" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">Менеджер</div>
              <a href="#" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="#" target="_blank" class="manager__link"
                >@publisher_push_house</a
              >
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div class="menu-bg" @click="openedSidebar = false" :class="{'active':openedSidebar}"></div>
  </div>
</template>

<script>
import serviceLanding from "@/api/serviceLanding";
import SelectLanguage from "@/components/SelectLanguage.vue";
import "@/assets/css/landing.css";

export default {
  name: "Landing",
  data() {
    return {
      openedSidebar: false,
      selectingCountry: false,
      selectedCountry:'',
      countries: [],
      banners: [
        {
          id: 0,
          image: require("@/assets/images/home/banner/banner-ph.png"),
          mob: require("@/assets/images/home/banner/banner-ph-mob.png"),
        },
        {
          id: 1,
          image: require("@/assets/images/home/banner/banner-clo.png"),
          mob: require("@/assets/images/home/banner/banner-clo-mob.png"),
        },
        {
          id: 1,
          image: require("@/assets/images/home/banner/banner-cpa.png"),
          mob: require("@/assets/images/home/banner/banner-cpa-mob.png"),
        },
        {
          id: 1,
          image: require("@/assets/images/home/banner/banner-spy.png"),
          mob: require("@/assets/images/home/banner/banner-spy-mob.png"),
        },
      ],
      currentFormat: 0,
      currentBanner: 0,
      benefits: [
        {
          value: "700M+",
          info: 'landing.daily_see',
          image: require("@/assets/images/home/benefits/benefit-1.svg"),
        },
        {
          value: "8",
          info: 'landing.withdraw_systems',
          image: require("@/assets/images/home/benefits/benefit-2.svg"),
        },
        {
          value: "99.9%",
          info: 'landing.ads_view',
          image: require("@/assets/images/home/benefits/benefit-3.svg"),
        },
        {
          value: "24/7",
          info:'support.title',
          image: require("@/assets/images/home/benefits/benefit-4.svg"),
        },
        {
          value: "185+",
          info:'landing.monetize_geo',
          image: require("@/assets/images/home/benefits/benefit-5.svg"),
        },
        {
          value: "4",
          info:'landing.ad_formats',
          image: require("@/assets/images/home/benefits/benefit-6.svg"),
        },
        {
          value: "4",
          info:'landing.payment_methods',
          image: require("@/assets/images/home/benefits/benefit-7.svg"),
        },
        {
          value: "3%",
          info:'landing.ref_system',
          image: require("@/assets/images/home/benefits/benefit-8.svg"),
        },
      ],
      formats: [
        {
          name: "Push Notifications",
          id: 0,
          image: require("@/assets/images/home/formats/push.png"),
          info: "landing.push_info",
          listOptions: [
            "landing.push_list1",
            "landing.push_list2",
            "landing.push_list3",
            "landing.push_list4",
          ],
        },
        {
          name: "In-Page",
          id: 1,
          image: require("@/assets/images/home/formats/in-page.png"),
          info: "landing.inPage_title",
          listOptions: [
            "landing.inPage_list1",
            "landing.inPage_list2",
            "landing.inPage_list3",
            "landing.inPage_list4",
          ],
        },
        {
          name: "On-click",
          id: 2,
          image: require("@/assets/images/home/formats/onclick.png"),
          info: "landing.onClick_title",
          listOptions: [
            "landing.onClick_list1",
            "landing.onClick_list2",
            "landing.onClick_list3",
            "landing.onClick_list4",
          ],
        },
        {
          name: "Native",
          id: 3,
          image: require("@/assets/images/home/formats/native.png"),
          info: "landing.native_title",
          listOptions: [
            "landing.native_list1",
            "landing.native_list2",
            "landing.native_list3",
            "landing.native_list4",
          ],
        },
      ],
    };
  },
  components: {
    SelectLanguage,
  },
  mounted() {
       serviceLanding.getTopCounties().then((res) => {
      if (res.status === 200) {
        this.countries = res.data;
      }
    });

    var myRange = document.querySelector("#trafficRange");
    var myValue = document.querySelector("#trafficValue");

    var off =
      myRange.offsetWidth / (parseInt(myRange.max) - parseInt(myRange.min));
    var px =
      (myRange.valueAsNumber - parseInt(myRange.min)) * off -
      myValue.offsetParent.offsetWidth / 2;

    myValue.parentElement.style.left = px+5 + "px";
    myValue.parentElement.style.top = myRange.offsetHeight + "px";
    myValue.innerHTML = myRange.value;

    myRange.oninput = function () {
      let px =
        (myRange.valueAsNumber - parseInt(myRange.min)) * off -
        myValue.offsetWidth / 2;
      myValue.innerHTML = myRange.value;
      myValue.parentElement.style.left = px-15 + "px";
    };
  },
 
  methods: {
     formatLabel(el) {
      return this.$t(el);
    },
    calcIncome(){
      document.querySelector('.calculate__total').innerHTML = '123'
    },
    close() {
      if (!this.openedSidebar) {
        return;
      }
      this.openedSidebar = false;
    },
  },
};
</script>


 